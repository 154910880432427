/*
 * Application
 */

$(document).tooltip({
    selector: "[data-toggle=tooltip]"
})


jQuery(".pb-down-arrow img").click(function(event){
    if (screen.width > 767) {
        jQuery('html, body').animate({scrollTop: '+=550px'}, 800);
    } 

    else {

      if (jQuery("body").hasClass("page-home")) {
        jQuery('html, body').animate({scrollTop: '+=275px'}, 800);
      }

      else if (jQuery("body").hasClass("page-homes-homesites")) {
        jQuery('html, body').animate({scrollTop: '+=290px'}, 800);
      }

      else if (jQuery("body").hasClass("page-builders-byob") || jQuery("body").hasClass("page-sitemap") || jQuery("body").hasClass("page-location") || jQuery("body").hasClass("page-disclaimer") || jQuery("body").hasClass("page-privacy-policy") ) {
        jQuery('html, body').animate({scrollTop: '+=200px'}, 800);
      }

      else {
          jQuery('html, body').animate({scrollTop: '+=315px'}, 800);   
      }
    }
});

/*
 * Auto hide navbar
 */
jQuery(document).ready(function($){
    var $header = $('.navbar-autohide'),
        scrolling = false,
        previousTop = 0,
        currentTop = 0,
        scrollDelta = 2.5,//10
        scrollOffset = 75

    $(window).on('scroll', function(){
        if (!scrolling && screen.width > 1199.98) {
            scrolling = true

            if (!window.requestAnimationFrame) {
                setTimeout(autoHideHeader, 250)
            }
            else {
                requestAnimationFrame(autoHideHeader)
            }
        }
    });

    $("body").keydown(function(e) {
      //console.log("detecto cualquier presion de tecla");
      //console.log("se detectó esta tecla: " + e.keyCode);
      if(e.keyCode == 40 ) { // left
        if (!scrolling && screen.width > 1199.98) {
          scrolling = true

          if (!window.requestAnimationFrame) {
              setTimeout(autoHideHeader, 250)
          }
          else {
              requestAnimationFrame(autoHideHeader)
          }
        }
      }
      else if(e.keyCode == 38) { // right
        if (!scrolling && screen.width > 1199.98) {
          scrolling = true

          if (!window.requestAnimationFrame) {
              setTimeout(autoHideHeader, 250)
          }
          else {
              requestAnimationFrame(autoHideHeader)
          }
        }
      }
    });

    function autoHideHeader() {
        var currentTop = $(window).scrollTop();
        //console.log( "previousTop(" + previousTop + ") - currentTop(" + currentTop + ") > scrollDelta(" + scrollDelta + ") && currentTop(" + currentTop + ") < scrollOffset( " + scrollOffset + ")" );


        // Scrolling up
        if (previousTop - currentTop > scrollDelta && currentTop < scrollOffset) {
            $header.removeClass('is-hidden');
            $header.addClass('is-visible');
        }
        else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
            // Scrolling down
            $header.addClass('is-hidden');
            $header.removeClass('is-visible');
        }

        previousTop = currentTop
        scrolling = false
    }



    /* Primary banner fade in text slider */

    $(".main-banner .container > h1:gt(0)").hide();

    setInterval(function () {
        $('.main-banner .container > h1:first')
            .fadeOut(1500);

        $('.main-banner .container > h1:first')
            .next()
            .fadeIn(1000)
            .end()
            .appendTo('.main-banner .container');
    }, 7000);

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

      var cityName = getUrlParameter('area');

      

      if(cityName) {

        if (screen.width > 767) {

          $('html, body').animate({
              scrollTop: $('#location-areas').offset().top
          }, 'slow');  
        }

        else if (screen.width > 361) {
          jQuery('html, body').animate({scrollTop: '+=1250px'}, 800);
        }

        else {
          jQuery('html, body').animate({scrollTop: '+=1200px'}, 800); 
        }

        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName).style.display = "block";
        jQuery("#" + cityName).addClass("active");
        jQuery(".btn-alc-" + cityName).addClass('active');
      }



      /* Turn hanburger icon into x icon on click */
      jQuery("button.navbar-toggle").click(function() {
        jQuery(this).toggleClass('turn-to-x');
        jQuery(".navbar-phone").toggle();
      });

      jQuery(".dropdown .m-d-a").click(function() {
        jQuery(this).parent().siblings().removeClass('dm-open');
        jQuery(this).parent().toggleClass('dm-open');
        jQuery('.dropdown.navbar-phone-in-menu').toggleClass('dm-open');
      })

      jQuery(".dropdown-menu>li>a").click(function() {
        jQuery("button.navbar-toggle").toggleClass('turn-to-x');
        jQuery(".navbar-phone").toggle();
        jQuery(".navbar-collapse").removeClass("in");
      });
    



/* JS for Master Plan */


    if( $('#master-plan-container').length ) {

      $(".mp-pin").click(function() {
        $(".mp-lot").removeClass("li-clicked");
        $(this).parent().addClass("li-clicked");
      });

      $(".mp-pin").hover(function() {
          $(".mp-lot").removeClass("li-pin-hovered");
          $(this).parent().addClass("li-pin-hovered");
        }, function() {
          var link = $(this);
          window.setTimeout(function() {
            $(link).parent().removeClass("li-pin-hovered");
          }, 100);
          
      });

      $(".mp-infobox").hover(function() {
          $(".mp-lot").removeClass("li-box-hovered");
          $(this).parent().addClass("li-box-hovered");
        }, function() {
          var link = $(this);
          window.setTimeout(function() {
            $(link).parent().removeClass("li-box-hovered");
          }, 500);
          
      });
    }

});


function openCity(evt, cityName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}

// Get the element with id="defaultOpen" and click on it
if (jQuery('#defaultOpen').length) {
    document.getElementById("defaultOpen").click();
}